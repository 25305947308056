

const Info = ({icon, text}) => {
  return(
    <div className="flex w-full flex-col gap-4 md:gap-6 lg:gap-8 xl:gap-4 md:pr-12 items-center md:items-start">
      <img className="h-20 relative md:right-4" src={icon} alt="not found" />
      <div className="text-md text-center md:text-start lg:text-lg xl:text-2xl" style={{fontWeight: "normal"}}>{text}</div>
    </div>
  )
}

export default Info;