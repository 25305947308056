import React from "react";
import MainPage from "./pages/MainPage";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Jeki from "./pages/JEKI";
import MoveYourBody from "./pages/MoveYourBody";
import HotPot from "./pages/HotPot";
import Datenschutz from "./pages/Datenschutz";
import PageNotFound from "./pages/PageNotFound";

const router = createBrowserRouter([
  {
      path: '/',
      element: <MainPage />
  },
  {
    path: '/about_us',
    element: <AboutUs />
  },
  {
    path: '/jeki',
    element: <Jeki />
  },
  {
    path: '/move_your_body',
    element: <MoveYourBody />
  },
  {
    path: '/hot_pot',
    element: <HotPot />
  },
  {
    path: '/datenschutz',
    element: <Datenschutz />
  },
  {
    path: '*',
    element: <PageNotFound />
  },

  
])

const App = () => {
  
  return (
      <RouterProvider router={router} future={{ v7_relativeSplatPath: true, v7_startTransition: true, v7_fetcherPersist: true }}/>
  );
}

export default App;
