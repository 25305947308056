import { useEffect } from "react";
import PageContainer from "./PageContainer";

const Datenschutz = () => {
    useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return(
    <PageContainer>
      <div className="w-full flex flex-col gap-8 lg:gap-12 xl:gap-12 mt-8 lg:mt-20 xl:mt-32">
        <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">Datenschutz</div>
        <div className="text-sm lg:text-lg xl:text-2xl w-full lg:w-3/4 text-left">
            <span>Der Betreiber dieser Website nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                Provider
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt.<br /> Dies sind:
            </span>
            <div className="py-8">
                <li>Browsertyp/ Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
            </div>
            <span>
                Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
            </span>

            <span>
                Gemäß E-Commerce-Gesetz (ECG) geben wir folgende Informationen bekannt: Medieninhaber und Herausgeber dieser Website:
            </span>
            <div className="text-lg lg:text-3xl xl:text-5xl font-semibold pt-12">ECG</div>
            <div className="py-8">
                <li>Jugend am Werk GmbH</li>
                <li>Lendplatz 35, 8020 Graz</li>
                <li>ARGE der Flexiblen Hilfen Hartberg-Fürstenfeld</li>
            </div>
            <span>Design dieser Website:</span>
            <div className="py-8">
                <li>archive42</li>
                <li>Bastian Hochstrasser</li>
                <li>Jakob Göslbauer</li>
            </div>
            <span className="block">Programmierung: Elias Halbauer</span>
            <br/>
            <span>Foto-Credits:</span> 
            <div className="pt-8">
                <li>Freepik.com</li>
                <li>Jakob Göslbauer</li>
            </div>

            <div className="text-lg lg:text-3xl xl:text-5xl font-semibold py-12">Ihre Rechte</div>
            <span>
                Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.  Ihr Vertrauen ist uns besonders wichtig. Sollten Sie daher weitere Fragen zum Thema Datenschutz haben, so wenden Sie sich bitte an folgenden Kontakt im Impressum.
            </span>
        </div>
        <div className="h-40">

        </div>

      </div>
    </PageContainer>
  )
}

export default Datenschutz;