
const ProjectInfo = ({project}) => {
  return(
    <div className="w-full">
       <div className="md:pt-12 xl:pt-28">
        <div className="grid grid-cols-2 gap-x-8 grid-rows-6 gap-y-4">
          <div className="row-start-1 md:row-end-3 row-end-1 col-start-1 col-end-3 md:col-end-1 text-center md:text-start text-2xl md:text-lg lg:text-3xl xl:text-5xl font-semibold self-center md:pt-8">{project.headline}</div>
          <div className="row-start-5 md:row-start-3 row-end-7 col-start-1 col-end-3 md:col-end-1 text-md lg:text-lg xl:text-2xl font-normal text-center md:text-start">{project.info}</div>
          <div className="row-start-2 md:row-start-1 md:row-end-7 row-end-5 col-start-1 col-end-3 md:col-start-2 flex justify-end items-center w-full">
            <img className="big_image_style rounded-3xl w-full md:w-5/6 object-cover" src={'./' + project.image} alt="not found"/>
          </div>
        </div>
       </div>
    </div>
  )
}

export default ProjectInfo;