import Info from "../components/project_details/Info";
import ProjectInfo from "../components/project_details/ProjectHeader";
import PageContainer from "./PageContainer";
import { useEffect } from "react";
import Gameboy from "../assets/web/spielsteine_web.svg"
import Miteinander from "../assets/web/person_web2.svg"
import Map from "../components/Map";


const Jeki = ({info}) => {

  
  const project = {
    headline:'Jugend Eltern Kind Info',
    info: 'Jugend Eltern Kind Informationen, kurz JEKI genannt, bietet in Gemeinden im Bezirk Hartberg-Fürstenfeld eine lokale, präventive und anonyme Informationsdrehscheibe an.',
    image: 'jeki_bus.png'
  }
  
  
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  // Call the function after the iframe loads
  return(
    <PageContainer>
      <div className="flex flex-col gap-8 md:gap-8 lg:gap-12 xl:gap-24">
        <ProjectInfo project={project}/>
        <div className="flex flex-col w-full gap-10 items-center md:items-start">
          <div className="text-2xl lg:text-3xl xl:text-5xl font-semibold pt-6">Rund um die Themen</div>
          <div className="flex flex-col md:grid md:grid-cols-2 md:grid-rows-1 gap-x-8 xl:gap-y-24 md:gap-y-12 gap-y-8">
            {/*<Info text={"Hier Text für Beistand"} icon={Zahnrad}/>*/}
            {/*<Info text={<span><span className="font-semibold">Beitrag</span> zur individuellen <span className='font-semibold'>Weiterentwicklung</span> eines einzelnen.</span>} icon={Zahnrad}/>*/}
            <Info text={<span><span className="font-semibold">Miteinander</span> ins <span className="font-semibold">Gespräch</span> kommen.</span>} icon={Miteinander}/>
            <Info text={<span><span className="font-semibold">Kennenlernen</span> der <span className="font-semibold">Angebotsvielfalt</span> zu den Themen <span className="font-semibold">Familie, Finanzen, Freizeit</span>.</span>} icon={Gameboy}/>
          </div>
          <div className="flex flex-col w-full text-center md:text-left md:w-3/5 gap-4 pt-8 md:pt-10">
            <div className="text-2xl lg:text-3xl xl:text-5xl font-semibold">Was bieten wir?</div>
            <div className="text-md lg:text-lg xl:text-2xl">JEKI, wie wir auch unseren Bus nennen, ist mit verschiedensten 
                                                            Bastel- und Outdoormaterialien ausgestattet, mit denen sich Kinder 
                                                            und Jugendliche auseinandersetzen dürfen, während das JEKI - Team 
                                                            mit den Erwachsenen ins Gespräch kommen kann. Wir sind bestens über Leistungen im Bezirk informiert und haben bei Bedarf Folder und Informationsmaterialien zu den verschiedensten Themen dabei.
            </div>
            <div/>
            <div className="text-sm lg:text-lg xl:text-2xl">Bei uns ist jede/r herzlich willkommen!</div>
            {/**
             <div>
               <iframe id="myIframe" src="http://127.0.0.1:5534/src/assets/map.html" style={{aspectRatio: 1/2}} width="80%"></iframe>
             </div>
             */}
          </div>
          <Map />
        </div>
      </div>
    </PageContainer>
  )
}

export default Jeki;