import { Link } from "react-router-dom";

const Project = ({project, isLeft}) => {

  return(
    <>
      <div className={`w-full h-min hidden md:flex justify-center ${isLeft ? '' : 'flex-row-reverse'}`}>
        <div className={`w-1/2 p-4 flex xl:py-20 xl:pr-10 xl:pl-14 ${isLeft ? '' : 'justify-end'}`}>
          <img className="rounded-3xl object-cover w-full object-top big_image_style h-36 lg:h-72 xl:h-96" src={'./'+project.img} alt="not found"/>
        </div>

        <div className="p-4 flex-grow xl:pl-10 xl:pr-14 w-1/2 flex flex-col gap-4 lg:gap-8 xl:gap-12 justify-center">
          <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">
            {project.title}
          </div>
          <div className="text-sm lg:text-lg xl:text-2xl">
            {project.text}
          </div>
          <Link to={project.link}>
            <div className="text-md xl:text-2xl cursor-pointer overflow-visible bg-yellow text-center flex items-center rounded-xl border-2 border-black p-2 xl:p-3 w-min text-nowrap">
              {project.link.startsWith('http')? 'Zur Website' : 'Mehr Lesen'}
            </div>
          </Link>
        </div>
        <div>
        </div>
      </div>
      

      <div className={`w-full h-min flex-col md:hidden justify-center text-center`}>
        <div className={`w-full p-4 flex-col xl:p-20`}>
          <div className="text-xl lg:text-3xl xl:text-5xl font-semibold py-4">
            {project.title}
          </div>
          <img className="rounded-3xl object-cover big_image_style object-top w-full" src={'./'+project.img} alt="not found"/>
        </div>

        <div className="p-4 xl:p-20 w-full flex flex-col gap-4 lg:gap-8 xl:gap-12 text-center">
          <div className="text-sm lg:text-lg xl:text-2xl">
            {project.text}
          </div>
          <div className="w-full items-center flex justify-center">
            <Link to={project.link}>
              <div className="text-md xl:text-2xl cursor-pointer overflow-visible bg-yellow text-center flex items-center rounded-xl border-2 border-black py-2 px-6 xl:p-3 w-min text-nowrap">
                {project.link.startsWith('http')? 'Zur Website' : 'Mehr Lesen'}
              </div>
            </Link>
          </div>
        </div>
        <div>
        </div>
      </div>
    </>
  )
}

export default Project;